import React, { useRef } from 'react';
import { useRouter } from 'next/router';

import './PersonalDashboardBanner.scss';
import sendEvent from '../../../helpers/tracking';
import { useMobile } from '../../../helpers/hooks';
import setUserPhoto from '../../../helpers/setUserPhoto';
import icons from '../../../shared/assets/icons/redesign';
import { getWeekContentLink } from '../../../helpers/userWeek';
import { isPartner, hasAutoEddStatus } from '../../../helpers/user';

const { ChevronRight, Logout, Pencil } = icons;

const tracking = {
  uitloggen: {
    mobile: ['dashboard-mobile', 'dashboard-mobile-uitloggen'],
    desktop: ['dashboard-desktop', 'dashboard-desktop-uitloggen'],
    partnerDesktop: [
      'dashboard-partner-desktop',
      'dashboard-partner-desktop-uitloggen'
    ],
    partnerMobile: [
      'dashboard-partner-mobile',
      'dashboard-partner-mobile-uitloggen'
    ]
  },
  profile: {
    mobile: ['dashboard-mobile', 'dashboard-mobile-profile'],
    desktop: ['dashboard-desktop', 'dashboard-desktop-profile'],
    partnerDesktop: [
      'dashboard-partner-desktop',
      'dashboard-partner-desktop-profile'
    ],
    partnerMobile: [
      'dashboard-partner-mobile',
      'dashboard-partner-mobile-profile'
    ]
  }
};

export default function PersonalDashboardBanner({
  user,
  config,
  NextLink,
  addUserEvent,
  setUserPhoto: setUserPhotoMutation
}) {
  const router = useRouter();
  const { provider, displayName, profilePicture } = user;

  const providerChannelLink = retrieveProviderChannelLink(user);

  const [isMobile] = useMobile();
  const uploadPhotoInput = useRef();

  const trackingKey = isPartner(user)
    ? isMobile
      ? 'partnerMobile'
      : 'partnerDesktop'
    : isMobile
    ? 'mobile'
    : 'desktop';

  return (
    <div className="_personalDashboardBanner">
      <div className="_personalDashboardBanner-content">
        <div
          onClick={() => sendEvent(...tracking.profile[trackingKey])}
          className="_personalDashboardBanner-user"
        >
          <input
            hidden
            type="file"
            accept="image/*"
            ref={uploadPhotoInput}
            onChange={(e) => {
              e.target.files &&
                e.target.files[0] &&
                setUserPhoto({
                  file: e.target.files[0],
                  setUserPhotoMutation
                });

              addUserEvent({
                variables: { type: 'popup-close:user-photo', data: '{}' }
              });
            }}
          />

          <div className="_personalDashboardBanner-user">
            <img
              className="_personalDashboardBanner-user-picture"
              src={profilePicture}
              alt="user-profile-picture"
              onClick={() => {
                uploadPhotoInput.current.click();
              }}
            />

            <div
              className="_personalDashboardBanner-user-picture-edit-icon cursor-pointer"
              onClick={() => {
                uploadPhotoInput.current.click();
              }}
            >
              <Pencil />
            </div>
          </div>

          <div>
            <p className="_personalDashboardBanner-user-name">{displayName}</p>

            <button
              className="_personalDashboardBanner-edit-profile-link"
              onClick={() => router.push('/profile-settings')}
            >
              Bewerk profiel <ChevronRight />
            </button>
          </div>
        </div>

        <div className="_personalDashboardBanner-info">
          <UserWeek user={user} NextLink={NextLink} />

          <div className="_personalDashboardBanner-info-item">
            <p className="_personalDashboardBanner-info-item-title">
              Zorgverlener
            </p>

            {!provider || !provider.displayName ? (
              <NextLink href="/profile-settings">
                <p className="_personalDashboardBanner-info-item-value">
                  Toevoegen
                  <ChevronRight className="_personalDashboardBanner-info-item-icon" />
                </p>
              </NextLink>
            ) : providerChannelLink ? (
              <NextLink href={providerChannelLink}>
                <p className="_personalDashboardBanner-info-item-value">
                  {provider.displayName}
                  <ChevronRight className="_personalDashboardBanner-info-item-icon" />
                </p>
              </NextLink>
            ) : (
              <p className="_personalDashboardBanner-info-item-value">
                {provider.displayName}
                <ChevronRight className="_personalDashboardBanner-info-item-icon" />
              </p>
            )}
          </div>
        </div>

        <div className="_personalDashboardBanner-logout-btn-wrap">
          <button
            onClick={() => logout()}
            className="_personalDashboardBanner-logout-btn"
          >
            <Logout className="_personalDashboardBanner-logout-icon" />
            Uitloggen
          </button>
        </div>
      </div>
    </div>
  );

  function logout() {
    sendEvent(...tracking.uitloggen[trackingKey]);

    document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'PNLSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    location.href = config.LOGOUT_URL;
  }
}

function UserWeek({ user, NextLink }) {
  if (['OTHER', 'ENDED'].includes(user.status)) return null;

  const isPregnant = user.status === 'PREGNANT';
  const isPreparing = user.status === 'PREPARING';

  const weekContentLink = getWeekContentLink(user);

  const textContent = isPreparing
    ? 'Voorbereiden'
    : isPregnant || user.phaseNumeric <= 17
    ? `Week ${user.phaseNumeric}`
    : `Maanden ${Math.floor(user.phaseNumeric / 4)}`;

  return (
    <div className="_personalDashboardBanner-info-item">
      <p className="_personalDashboardBanner-info-item-title">
        {isPregnant || isPreparing || hasAutoEddStatus(user)
          ? 'Zwangerschap'
          : 'Baby'}
      </p>
      {isPreparing ? (
        <p className="_personalDashboardBanner-info-item-value">
          {textContent}
        </p>
      ) : weekContentLink ? (
        <NextLink href={weekContentLink}>
          <p className="_personalDashboardBanner-info-item-value">
            {textContent}
            <ChevronRight className="_personalDashboardBanner-info-item-icon" />
          </p>
        </NextLink>
      ) : (
        <p className="_personalDashboardBanner-info-item-value">
          {textContent}
        </p>
      )}
    </div>
  );
}

export function retrieveProviderChannelLink({ provider }) {
  if (!provider) return;

  return (
    (provider.primaryChannel &&
      provider.primaryChannel.uri &&
      `channels/${provider.primaryChannel.uri}`) ||
    (provider.aboutContent && provider.aboutContent.uri)
  );
}
