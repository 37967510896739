import React, { useEffect, useRef } from 'react';

import './ToastNotification.scss';

export default function ToastNotification({ children }) {
  const ref = useRef();

  useEffect(() => {
    setTimeout(() => {
      ref.current.classList.add('toastNotification-show');

      setTimeout(() =>
        ref.current.classList.remove('toastNotification-show'), 6000);
    }, 1000)
  }, [])

  return (
    <div className="toastNotification" ref={ref}>
      <div className="toastNotification-content">
        {children}
      </div>

      <div className="toastNotification-loading" />
    </div>
  )
}
