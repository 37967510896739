import React from 'react';

import './SurveyCompleteScreen.scss';
import icons from '../../../shared/assets/icons/redesign';

const { Check, Logo } = icons;

export default function SurveyCompleteScreen(props) {
  return (
    <section className="surveyCompleteScreen">
      <Logo className="surveyCompleteScreen-logo" />

      <div className="surveyCompleteScreen-content">
        <div className="surveyCompleteScreen-check-icon-wrap">
          <Check className="surveyCompleteScreen-check-icon" />
        </div>

        <h3 className="surveyCompleteScreen-title">
          {props.title}
        </h3>

        <p className="surveyCompleteScreen-text">
          {props.text}
        </p>

        <div className="surveyCompleteScreen-form-button-wrap">
          {props.buttons.map(button =>
            <a href={button.link}>
              <button className="surveyCompleteScreen-form-button">
                {button.label}
              </button>
            </a>)}
        </div>
      </div>
    </section>
  );
}
