import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import './ContentList.scss';
import sendEvent from '../../../helpers/tracking';
import icons from '../../../shared/assets/icons/redesign';
import { srcSet, useDimensions } from '../../../helpers/image';

const { Dot } = icons;

export default function ContentList({
  NextLink,
  contentQuery,
  apolloClient,
  trackingCodes,
  contentUriList,
  loadMoreContentUriList
}) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    (async () => {
      await load(contentUriList);
    })();

    setLoading(false);
  }, []);

  if (loading)
    return <div className="contentList-loader-mobile">Loading...</div>;

  return (
    !!list.length && (
      <div className="contentList">
        <InfiniteScroll
          dataLength={list.length}
          next={async () => {
            await load(loadMoreContentUriList);
            setHasMore(false);
          }}
          hasMore={hasMore}
          scrollThreshold="0.5"
          className="contentList-items"
          loader={<p className="contentList-loader">Loading...</p>}
        >
          {list.map((item, idx) => (
            <ListItem
              key={idx}
              item={item}
              id={idx + 1}
              NextLink={NextLink}
              trackingCodes={trackingCodes}
            />
          ))}
        </InfiniteScroll>
      </div>
    )
  );

  async function load(uris) {
    try {
      const data = await Promise.all(
        uris.map((uri) =>
          apolloClient.query({
            query: contentQuery,
            variables: { uri }
          })
        )
      );

      const fetchedList = data.map(({ data = {} }) => {
        return {
          uri: data.content.uri,
          title: data.content.title,
          imageUrl: data.content.imageUrl,
          category: data.content.parent.title,
          authorLogo: data.content.author.imageUrl,
          authorName: data.content.author.displayName
        };
      });

      setList([...list, ...fetchedList]);
    } catch (e) {}
  }
}

function ListItem({ id, item, NextLink, trackingCodes }) {
  const ref = useRef();
  const dimensions = useDimensions(ref);

  return (
    <NextLink href={item.uri}>
      <div
        className="contentList-item"
        onClick={() => sendEvent(...trackingCodes(id))}
      >
        <div ref={ref} className="contentList-item-image">
          {dimensions && (
            <img
              alt="content-image"
              srcSet={srcSet(item.imageUrl, dimensions)}
            />
          )}
        </div>

        <div className="contentList-item-author">
          <img
            className="contentList-item-author-logo"
            alt={item.authorName}
            src={item.authorLogo}
          />

          <p className="contentList-item-author-name">{item.authorName}</p>

          <Dot className="contentList-item-dot" />

          <p className="contentList-item-category">{item.category}</p>
        </div>

        <h4 className="contentList-item-title">{item.title}</h4>
      </div>
    </NextLink>
  );
}
