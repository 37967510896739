import React from 'react';

import './ArticleList.scss';
import Dot from '../../../shared/assets/icons/redesign/dot.svg';

export default function ArticleList({ list, NextLink }) {
  return (
    <ul className="_articleList">
      {list[0] &&
        <ArticleListFirstItem
          item={list[0]}
          NextLink={NextLink}
        />}

      {list.slice(1).map(item =>
        <ArticleListItem
          key={item.id}
          NextLink={NextLink}
          item={item}
        />)}
    </ul>
  );
}

function ArticleListFirstItem({ item, NextLink }) {
  const articlePostedDate = formatDate(item.postedAt);

  return (
    <NextLink href={`/${item.uri}`}>
      <li className="_articleList-first-item">
        <div className="_articleList-first-item-author">
          <img
            alt="author-image"
            src={item.author.imageUrl}
            className="_articleList-first-item-author-image"
          />

          <p className="_articleList-first-item-author-name">
            {item.author.displayName}
          </p>

          {articlePostedDate &&
            <p className="_articleList-first-item-posted-date">
              <Dot className="_articleList-first-item-dot" />

              {articlePostedDate}
            </p>}
        </div>

        <h4 className="_articleList-first-item-title">
          {item.title}
        </h4>

        <img
          alt="article-image"
          src={item.imageUrl}
          className="_articleList-first-item-image"
        />
      </li>
    </NextLink>
  );
}

function ArticleListItem({ item, NextLink }) {
  return (
    <NextLink href={`/${item.uri}`}>
      <li className="_articleList-item">
        <img
          src={item.imageUrl}
          alt="article-image"
          className="_articleList-item-image"
        />

        <div className="_articleList-item-data">
          <div className="_articleList-item-author">
            <img
              alt="author-image"
              src={item.author.imageUrl}
              className="_articleList-item-author-image"
            />

            <p
              className="_articleList-item-author-name">
              {item.author.displayName}
            </p>
          </div>

          <h5 className="_articleList-item-title">
            {item.title}
          </h5>
        </div>
      </li>
    </NextLink>
  );
}

function formatDate(d) {
  const date = new Date(d.replace(/-/g, '/'));

  if (!date.getDate()) return null;

  return date.toLocaleDateString('nl', {
    day: 'numeric', year: 'numeric', month: 'long'
  });
}
