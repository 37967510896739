import 'video.js/dist/video-js.css';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import './CoursesIntro.scss';
import Transition from '../../../Transition/Transition.jsx';
import icons from '../../../../shared/assets/icons/redesign';
import CoursesLoginPopup from '../../../CoursesLoginPopup/CoursesLoginPopup.jsx';
import CoursesPlans from '../CoursesPlans/CoursesPlans';
import { isImage } from '../../../../helpers/image';

const { ChevronRight } = icons;

const defaultPlans = (user, query) => [
  {
    name: 'Basis',
    normaal: '149,95',
    nu: '99,95',
    type: 'online-cursussen-basis',
    monthsDuration: 12,
    list: [
      {
        displayedName: 'Zwangerschap',
        name: 'zwangerschapscursus',
        hasEnglish: true,
        isAvailable: true,
        isDisplayed: true
      },
      {
        displayedName: 'Mindfulness zwangerschap',
        name: 'mindfulnesscursus',
        hasEnglish: false,
        isAvailable: false,
        isDisplayed: true
      },
      {
        displayedName: 'Bevallen',
        name: 'bevallingscursus',
        hasEnglish: true,
        isAvailable: true,
        isDisplayed: true
      },
      {
        displayedName: 'Dragen en hechting',
        name: 'dragen-en-hechting',
        hasEnglish: false,
        isAvailable: false,
        isDisplayed: true
      },
      {
        displayedName: 'Borstvoeding',
        name: 'borstvoedingscursus',
        hasEnglish: true,
        isAvailable: true,
        isDisplayed: true
      },
      {
        displayedName: 'Slaapcursus 0-6 maanden',
        name: 'slaapcursus0-6maanden',
        hasEnglish: false,
        isAvailable: false,
        isDisplayed: true
      },
      {
        name: 'zwangerschapsyoga',
        displayedName: 'Zwangerschapsyoga',
        hasEnglish: true,
        isAvailable: true
      },
      {
        name: 'sporten-en-bewegen',
        displayedName: 'Sporten en bewegen',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'voedingscursus',
        displayedName: 'Voeding',
        hasEnglish: true,
        isAvailable: true
      },
      {
        name: 'snelcursus-voor-de-partner',
        displayedName: 'Snelcursus voor de partner',
        hasEnglish: true,
        isAvailable: true
      },
      {
        name: 'hypnobirthing',
        displayedName: 'Introductie in HypnoBirthing®',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'seksualiteit-tijdens-en-na-de-zwangerschap',
        displayedName: 'Seksualiteit tijdens en na de zwangerschap',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'herstel-na-de-bevalling-sep',
        displayedName: 'Herstel na de bevalling',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'de-eerste-100-dagen-na-de-bevalling',
        displayedName: 'De eerste 100 dagen na de bevalling',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'babymassage',
        displayedName: 'Babymassage',
        isAvailable: false,
        hasEnglish: true
      },
      {
        name: 'babysignalen-en-baby-en-kinder-gebaren',
        displayedName: 'Babysignalen en baby en kinder gebaren',
        isAvailable: false,
        hasEnglish: false
      },
      {
        name: 'slaapcursus-pakket',
        displayedName: 'Slaapcursus 6 maanden tot 5 jaar',
        isAvailable: false,
        hasEnglish: false
      },
      {
        name: 'mindfulness-na-bevalling',
        displayedName: 'Mindfullness na de bevalling',
        isAvailable: false,
        hasEnglish: false
      }
    ]
  },
  {
    name: 'Plus',
    normaal: '239,95',
    isMostPopular: true,
    type: 'online-cursussen-plus',
    monthsDuration: 18,
    // https://team.linkorb.com/cards/8927
    ...(query.dimension5 === 'not-p34-s3'
      ? {
          nu: '99,95',
          type: 'online-cursussen'
        }
      : query.dimension5 === 'not-74-s3'
        ? {
            nu: '74,95',
            type: 'online-cursussen-offer1'
          }
        : {
            nu: '139,95',
            type: 'online-cursussen-plus'
          }),
    list: [
      {
        displayedName: 'Zwangerschap',
        name: 'zwangerschapscursus',
        hasEnglish: true,
        isAvailable: true,
        isDisplayed: true
      },
      {
        displayedName: 'Mindfulness zwangerschap',
        name: 'mindfulnesscursus',
        hasEnglish: false,
        isAvailable: true,
        isDisplayed: true
      },
      {
        displayedName: 'Bevallen',
        name: 'bevallingscursus',
        hasEnglish: true,
        isAvailable: true,
        isDisplayed: true
      },
      {
        displayedName: 'Dragen en hechting',
        name: 'dragen-en-hechting',
        hasEnglish: false,
        isAvailable: true,
        isDisplayed: true
      },
      {
        displayedName: 'Borstvoeding',
        name: 'borstvoedingscursus',
        hasEnglish: true,
        isAvailable: true,
        isDisplayed: true
      },
      {
        displayedName: 'Slaapcursus 0-6 maanden',
        name: 'slaapcursus0-6maanden',
        hasEnglish: false,
        isAvailable: true,
        isDisplayed: true
      },
      {
        name: 'zwangerschapsyoga',
        displayedName: 'Zwangerschapsyoga',
        hasEnglish: true,
        isAvailable: true
      },
      {
        name: 'sporten-en-bewegen',
        displayedName: 'Sporten en bewegen',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'voedingscursus',
        displayedName: 'Voeding',
        hasEnglish: true,
        isAvailable: true
      },
      {
        name: 'snelcursus-voor-de-partner',
        displayedName: 'Snelcursus voor de partner',
        hasEnglish: true,
        isAvailable: true
      },
      {
        name: 'hypnobirthing',
        displayedName: 'Introductie in HypnoBirthing®',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'seksualiteit-tijdens-en-na-de-zwangerschap',
        displayedName: 'Seksualiteit tijdens en na de zwangerschap',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'herstel-na-de-bevalling-sep',
        displayedName: 'Herstel na de bevalling',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'de-eerste-100-dagen-na-de-bevalling',
        displayedName: 'De eerste 100 dagen na de bevalling',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'babymassage',
        displayedName: 'Babymassage',
        isAvailable: true,
        hasEnglish: true
      },
      {
        name: 'babysignalen-en-baby-en-kinder-gebaren',
        displayedName: 'Babysignalen en baby en kinder gebaren',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'slaapcursus-pakket',
        displayedName: 'Slaapcursus 6 maanden tot 5 jaar',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'mindfulness-na-bevalling',
        displayedName: 'Mindfullness na de bevalling',
        isAvailable: true,
        hasEnglish: false
      }
    ]
  },
  {
    name: 'Pro',
    normaal: '299,95',
    nu: '179,95',
    type: 'online-cursussen-pro',
    monthsDuration: 36,
    list: [
      {
        displayedName: 'Zwangerschap',
        name: 'zwangerschapscursus',
        hasEnglish: true,
        isAvailable: true,
        isDisplayed: true
      },
      {
        displayedName: 'Mindfulness zwangerschap',
        name: 'mindfulnesscursus',
        hasEnglish: false,
        isAvailable: true,
        isDisplayed: true
      },
      {
        displayedName: 'Bevallen',
        name: 'bevallingscursus',
        hasEnglish: true,
        isAvailable: true,
        isDisplayed: true
      },
      {
        displayedName: 'Dragen en hechting',
        name: 'dragen-en-hechting',
        hasEnglish: false,
        isAvailable: true,
        isDisplayed: true
      },
      {
        displayedName: 'Borstvoeding',
        name: 'borstvoedingscursus',
        hasEnglish: true,
        isAvailable: true,
        isDisplayed: true
      },
      {
        displayedName: 'Slaapcursus 0-6 maanden',
        name: 'slaapcursus0-6maanden',
        hasEnglish: false,
        isAvailable: true,
        isDisplayed: true
      },
      {
        name: 'zwangerschapsyoga',
        displayedName: 'Zwangerschapsyoga',
        hasEnglish: true,
        isAvailable: true
      },
      {
        name: 'sporten-en-bewegen',
        displayedName: 'Sporten en bewegen',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'voedingscursus',
        displayedName: 'Voeding',
        hasEnglish: true,
        isAvailable: true
      },
      {
        name: 'snelcursus-voor-de-partner',
        displayedName: 'Snelcursus voor de partner',
        hasEnglish: true,
        isAvailable: true
      },
      {
        name: 'hypnobirthing',
        displayedName: 'Introductie in HypnoBirthing®',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'seksualiteit-tijdens-en-na-de-zwangerschap',
        displayedName: 'Seksualiteit tijdens en na de zwangerschap',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'herstel-na-de-bevalling-sep',
        displayedName: 'Herstel na de bevalling',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'de-eerste-100-dagen-na-de-bevalling',
        displayedName: 'De eerste 100 dagen na de bevalling',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'babymassage',
        displayedName: 'Babymassage',
        isAvailable: true,
        hasEnglish: true
      },
      {
        name: 'babysignalen-en-baby-en-kinder-gebaren',
        displayedName: 'Babysignalen en baby en kinder gebaren',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'slaapcursus-pakket',
        displayedName: 'Slaapcursus 6 maanden tot 5 jaar',
        isAvailable: true,
        hasEnglish: false
      },
      {
        name: 'mindfulness-na-bevalling',
        displayedName: 'Mindfullness na de bevalling',
        isAvailable: true,
        hasEnglish: false
      }
    ]
  }
];

export default function CoursesIntro({
  list = [],
  config,
  user,
  hideIntro,
  addUserEvent
}) {
  const router = useRouter();
  const [popup, showPopup] = useState(false);

  const plans = defaultPlans(user, router.query);

  return (
    <div className="coursesIntro">
      <Transition on={popup}>
        <CoursesLoginPopup
          config={config}
          showPopup={showPopup}
          customList={[
            '18 meest populaire cursussen',
            'ruim 1500 min. aan professionele video’s',
            '(deels) vergoed door je zorgverzekeraar'
          ]}
        />
      </Transition>

      {!hideIntro && (
        <>
          <h1 className="coursesIntro-title">
            Volg jouw (zwangerschaps)cursus nu lekker vanuit huis!
          </h1>

          <p className="coursesIntro-text">
            Volg jouw (zwangerschaps)cursus nu lekker vanuit huis! Een compleet
            cursusportaal waar je zowel zwangerschaps-, beval- en babycursussen
            krijgt? Het bestaat. Met momenteel 18 cursussen zorgen onze
            professionals ervoor dat jij en je partner alles aankunnen wat er op
            jullie pad komt tijdens en na de zwangerschap. Leer alles wat je
            wilt weten en volg de cursus op jouw moment, in jouw veilige
            thuisomgeving.
          </p>

          <h2 className="coursesIntro-subtitle">Top 3 populaire cursussen:</h2>

          <div className="coursesIntro-images">
            <a
              href="#zwangerschapscursus"
              className="coursesIntro-image-container"
            >
              <img
                src="https://upr.io/pR3xLA.webp"
                alt="cursus-zwangerschap"
                className="coursesIntro-image"
              />

              <div className="coursesIntro-image-overlay">
                <p className="coursesIntro-image-caption">Zwangerschap</p>
              </div>
            </a>

            <a
              href="#bevallingscursus"
              className="coursesIntro-image-container"
            >
              <img
                src="https://upr.io/AmMRWA.webp"
                alt="cursus-bevallen"
                className="coursesIntro-image"
              />

              <div className="coursesIntro-image-overlay">
                <p className="coursesIntro-image-caption">Bevallen</p>
              </div>
            </a>

            <a
              href="#borstvoedingscursus"
              className="coursesIntro-image-container"
            >
              <img
                src="https://upr.io/P140nA.webp"
                alt="cursus-borstvoeding"
                className="coursesIntro-image"
              />

              <div className="coursesIntro-image-overlay">
                <p className="coursesIntro-image-caption">Borstvoeding</p>
              </div>
            </a>
          </div>

          <div className="coursesIntro-text-small">
            <img
              alt="courses-users"
              src="https://upr.io/dBEyMP.png"
              className="coursesIntro-img"
            />

            <p>
              Maartje en
              <span className="coursesIntro-text-bold">
                {' '}
                {calculateCoursesMemberCount()} anderen{' '}
              </span>
              hebben toegang tot cursussen.
            </p>
          </div>

          <h2 className="coursesIntro-subtitle">Wat onze cursisten zeggen:</h2>

          <ul className="coursesIntro-review-list">
            <li className="coursesIntro-review-list-item">
              <p>
                Zwangerschapscursus
                <br />
                <span>9.5</span>
              </p>
              <q>Geweldige cursus. En helemaal gratis!</q>
            </li>

            <li className="coursesIntro-review-list-item">
              <p>
                Bevallingscursus
                <br />
                <span>8.0</span>
              </p>
              <q>Informatief, prettig om naar te kijken</q>
            </li>

            <li className="coursesIntro-review-list-item">
              <p>
                Borstvoedingscursus
                <br />
                <span>8.3</span>
              </p>
              <q>Fijn overzicht</q>
            </li>

            <li className="coursesIntro-review-list-item">
              <p>
                Snelcursus voor de partner
                <br />
                <span>8.1</span>
              </p>
              <q>
                Heel fijn om deze cursussen samen met mijn partner te bekijken
              </q>
            </li>

            <li className="coursesIntro-review-list-item">
              <p>
                Seksualiteit tijdens en na de zwangerschap
                <br />
                <span>8.3</span>
              </p>
              <q>Doorbreekt taboe en dat is prettig</q>
            </li>
          </ul>
        </>
      )}

      <div className="coursesIntro-featured-box">
        <p className="coursesIntro-featured-box-text">
          <span className="coursesIntro-featured-box-text-bold">
            Wist je dat ouders gemiddeld 30% meer vertrouwen krijgen dankzij het
            volgen van de cursussen?
          </span>
          Bijvoorbeeld 38% meer vertrouwen in borstvoeding en 26% meer
          vertrouwen in de bevalling!
        </p>
      </div>

      <CoursesPlans
        user={user}
        plans={plans}
        showPopup={showPopup}
        addUserEvent={addUserEvent}
      />

      <h2 className="coursesIntro-video-list-title">Cursussen:</h2>

      <ul className="coursesIntro-video-list">
        {plans[0].list.map((course) => {
          const { displayName, name, courseModules, userImageUrl, username } =
            list.find(({ name }) => course.name === name) || {};

          if (!name) return;

          const introductionVideo =
            courseModules.find(
              (module) => module.name === 'Introductievideo'
            ) || {};

          return (
            <li key={name} className="coursesIntro-video-list-item" id={name}>
              <div className="coursesIntro-video-list-item-header">
                <h3>{displayName}</h3>

                {!isImage(introductionVideo.url) && (
                  <a
                    className="coursesIntro-video-list-item-header-link"
                    href={`/zwangerschapscursus/${name}/${
                      name === 'bevallingscursus'
                        ? 'introductievideobevalingscursus'
                        : 'introductievideo'
                    }`}
                  >
                    Lees meer{' '}
                    <ChevronRight className="coursesIntro-video-list-item-header-link-icon icon" />
                  </a>
                )}
              </div>

              <div className="coursesIntro-video-list-item-body">
                {isImage(introductionVideo.url) ? (
                  <img
                    src={introductionVideo.url}
                    alt="course-image"
                    className="coursesIntro-video-list-item-img"
                  />
                ) : (
                  <VideoItem item={introductionVideo} list={list} />
                )}

                <div className="coursesIntro-video-list-item-info">
                  <span>Introductie</span>

                  <div className="coursesIntro-video-list-item-author">
                    <img
                      className="coursesIntro-video-list-item-author-image"
                      src={userImageUrl}
                      alt="course-author-image"
                    />
                    <span className="coursesIntro-video-list-item-author-name">
                      {username}
                    </span>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function VideoItem({ item }) {
  const playerRef = useRef();

  useEffect(() => {
    (async () => {
      const videojs = (await import('video.js')).default;

      videojs(playerRef.current, {
        fluid: true,
        controls: true,
        sources: [{ src: item.url, type: 'video/mp4' }],
        playbackRates: [0.5, 1, 1.5, 2]
      });
    })();
  }, []);

  return (
    <div className="coursesIntro-video-list-item-video">
      <div data-vjs-player>
        <video ref={playerRef} className="video-js" poster={item.imageUrl}>
          {item?.subtitleUrl && (
            <track
              kind="captions"
              src={item.subtitleUrl}
              srcLang="en"
              label="English"
            />
          )}
        </video>
      </div>
    </div>
  );
}

function calculateCoursesMemberCount() {
  const initialDateToTrackCoursesMemberCount = new Date('2023-02-11');

  const differenceInDays = Math.ceil(
    (new Date().getTime() - initialDateToTrackCoursesMemberCount.getTime()) /
      (1000 * 3600 * 24)
  );

  return (7891 + differenceInDays * 3).toLocaleString('nl-NL');
}
