import React, { useState } from 'react';
import { useRouter } from 'next/router';

import './AppointmentList.scss';

import { getString } from '../../../helpers/styles';
import icons from '../../../shared/assets/icons/redesign';
import NoProviderMessage from '../../NoProviderMessage/NoProviderMessage.jsx';

const {
  Info,
  Clock,
  Calendar,
  PhoneCheck,
  EmailCheck,
  ChevronLeft,
  CalendarCheck,
  CalendarSimple
} = icons;

export default function AppointmentList({ user, list }) {
  const router = useRouter();
  const [upcomingList, setUpcomingList] = useState(true);

  const appointmentList = (list.appointments || []).filter((appointment) => {
    const now = new Date();
    const appointmentDate = new Date(`${appointment.startAt}Z`);

    const isFutureDate = appointmentDate.getTime() - now.getTime() > 0;

    return upcomingList ? isFutureDate : !isFutureDate;
  });

  if (!user.provider || !user.provider.accountName)
    return <NoProviderMessage />;

  return (
    <section className="appointmentList">
      <div className="appointmentList-header">
        <h2 className="appointmentList-header-title">
          {upcomingList ? 'Mijn afspraken' : 'Vorige afspraken'}
        </h2>

        <button
          onClick={() =>
            upcomingList ? router.push('/dashboard') : setUpcomingList(true)
          }
          className="
            appointmentList-header-button
            appointmentList-header-back-button"
        >
          <ChevronLeft
            className="
            appointmentList-header-button-icon
            appointmentList-header-back-button-icon"
          />
          Terug
        </button>

        {upcomingList && (
          <button
            className="
              appointmentList-header-button
              appointmentList-header-past-appointments-button"
            onClick={() => {
              window.scrollTo(0, 0);
              setUpcomingList(!upcomingList);
            }}
          >
            <CalendarCheck
              className="
                appointmentList-header-button-icon
                appointmentList-header-past-appointments-button-icon"
            />

            <span className="appointmentList-header-past-appointments-button-label">
              Vorige afspraken
            </span>
          </button>
        )}
      </div>

      {(user.provider.phone || user.provider.email) && (
        <div className="appointmentList-alert">
          <div className="appointmentList-alert-message">
            <Info className="appointmentList-alert-message-icon" />
            Ben je verhinderd of is de afspraak onjuist? Neem contact op met je
            zorgverlener.
          </div>

          <div className="appointmentList-alert-buttons">
            {user.provider.phone && (
              <a
                className="appointmentList-alert-button"
                href={`tel:${user.provider.phone}`}
              >
                <PhoneCheck className="appointmentList-alert-button-icon" />
                Bel zorgverlener
              </a>
            )}
            {user.provider.email && (
              <a
                className="appointmentList-alert-button"
                href={`mailto:${user.provider.email}`}
              >
                <EmailCheck className="appointmentList-alert-button-icon" />
                Mail zorgverlener
              </a>
            )}
          </div>
        </div>
      )}

      <ul className="appointmentList-content">
        {appointmentList
          .sort((a, b) => {
            const date1 = new Date(`${a.startAt}Z`);
            const date2 = new Date(`${b.startAt}Z`);

            return upcomingList
              ? date1.getTime() - date2.getTime()
              : date2.getTime() - date1.getTime();
          })
          .map((appointment, idx) => {
            const date = new Date(`${appointment.startAt}Z`);

            return (
              <li className="appointmentList-item" key={appointment.id}>
                <div className="appointmentList-item-border">
                  <div
                    className={getString([
                      {
                        value: 'appointmentList-item-icon-wrap',
                        condition: true
                      },
                      {
                        value: 'appointmentList-item-icon-wrap-active',
                        condition: appointment.organization.feature
                      }
                    ])}
                  >
                    <Calendar className="appointmentList-item-icon" />
                  </div>

                  <div
                    className="appointmentList-item-line"
                    hidden={appointmentList.length - 1 === idx}
                  />
                </div>

                <div className="appointmentList-item-content">
                  <img
                    src={appointment.organization.logoUrl}
                    alt="provider-image"
                    className="appointmentList-item-content-provider-image"
                  />

                  <p className="appointmentList-item-text">
                    {appointment.organization.displayName}
                  </p>

                  <div className="appointmentList-item-date">
                    <p className="appointmentList-item-text">
                      <CalendarSimple className="appointmentList-item-date-icon" />

                      {date.toLocaleDateString('nl').replace(/\//g, '-')}
                    </p>

                    <p className="appointmentList-item-text">
                      <Clock className="appointmentList-item-date-icon" />

                      {date.toLocaleString('en-US', {
                        hour12: false,
                        timeZone: 'UTC',
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </p>
                  </div>

                  <p className="appointmentList-item-text-small">
                    {appointment.typeTitle}
                    <br />
                    {appointment.typeDescription}
                  </p>

                  {appointment.details && (
                    <p className="appointmentList-item-text-small">
                      {appointment.details}
                    </p>
                  )}
                </div>
              </li>
            );
          })}
      </ul>
    </section>
  );
}
