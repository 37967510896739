import React, { useState } from 'react';

import './NotificationBanner.scss';
import sendEvent from '../../../helpers/tracking';
import { useMobile } from '../../../helpers/hooks';
import icons from '../../../shared/assets/icons/redesign';

const { Close } = icons;

export default function NotificationBanner(
  { NextLink, item: { heading, message, buttonLabel, url } }
) {
  const [isMobile, setIsMobile] = useMobile();
  const [isHidded, setIsHidden] = useState(false);

  if (isHidded) return null;

  return (
    <div className="_notificationBanner">
      <Close
        className="_notificationBanner-close-icon"
        onClick={() => (setIsHidden(true), track(isMobile))}
      />

      <h4 className="_notificationBanner-heading">{heading}</h4>
      <p className="_notificationBanner-message">{message}</p>

      <NextLink href={url}>
        <button
          onClick={() => track(isMobile)}
          className="_notificationBanner-button">
          {buttonLabel}
        </button>
      </NextLink>
    </div>
  );
}

function track(isMobile) {
  if (isMobile)
    sendEvent('dashboard-mobile', 'dashboard-mobile-notificationsindiv');
  else
    sendEvent('dashboard-desktop', 'dashboard-desktop-notificationsindiv');
}
