import './CoursesPlans.scss';
import { useRouter } from 'next/router';
import { useState } from 'react';
import icons from '../../../../shared/assets/icons/redesign';

const { ItemCheck, Cross, ChevronUp, ChevronDown, Check } = icons;

export default function CoursesPlans({ user, showPopup, plans, addUserEvent }) {
  const router = useRouter();
  const handleClick = (paymentItemType) => {
    addUserEvent({
      variables: { type: 'courses-buybutton', data: '{}' }
    });

    if (!user.username) return showPopup(true);

    router.push(
      `/zwangerschapscursus/betalen/verzekering?type=${paymentItemType}`
    );
  };

  return (
    <div className="coursesPlans">
      <h2 className="coursesPlans-title">Kies het pakket wat bij jou past:</h2>

      {plans.map((plan) => (
        <CoursePlan plan={plan} key={plan.name} handleClick={handleClick} />
      ))}

      <div className="coursesPlans-list-wrap">
        <div>
          <h3 className="coursesIntro-subtitle">Het cursusportaal:</h3>

          <ul className="coursesPlans-list">
            <li className="coursesPlans-list-item">
              <div className="coursesPlans-list-item-icon-wrap">
                <Check className="coursesPlans-list-item-icon icon" />
              </div>
              18 meest populaire cursussen
            </li>

            <li className="coursesPlans-list-item">
              <div className="coursesPlans-list-item-icon-wrap">
                <Check className="coursesPlans-list-item-icon icon" />
              </div>
              ruim 1500 min. aan professionele video’s
            </li>

            <li className="coursesPlans-list-item">
              <div className="coursesPlans-list-item-icon-wrap">
                <Check className="coursesPlans-list-item-icon icon" />
              </div>
              (deels) vergoed door je zorgverzekeraar
            </li>
          </ul>
        </div>
      </div>

      <div className="coursesPlans-reimbursement-container">
        <h2 className="coursesPlans-reimbursement-title">
          Vergoedt mijn zorgverzekeraar dit?
        </h2>
        <p className="coursesPlans-reimbursement-text">
          Ruim 80% van de zorgverzekeraars vergoedt ons cursusportaal. Kom er{' '}
          <a
            href="/verzekering/cursus-vergoeding"
            className="coursesPlans-reimbursement-link"
          >
            hier
          </a>{' '}
          achter of jouw verzekeraar ons online cursuspakket ook vergoedt.
        </p>
      </div>
    </div>
  );
}

function CoursePlan({ plan, handleClick }) {
  const [isExpanded, setExpanded] = useState(false);

  const displayedList = isExpanded ? plan.list : plan.list.slice(0, 6);

  const availableCoursesCount = plan.list.filter(
    (item) => item.isAvailable
  ).length;

  return (
    <div className="coursesPlans-pricing-card">
      <div className="coursesPlans-card-header">
        <div>
          <div className="coursesPlans-plan-type-wrap">
            <h3 className="coursesPlans-plan-type">{plan.name}</h3>
            {plan.isMostPopular && (
              <span className="coursesPlans-most-chosen">Meest gekozen</span>
            )}
          </div>

          <span className="coursesPlans-course-access">
            {availableCoursesCount} cursussen en {plan.monthsDuration} maanden
            toegang
          </span>
        </div>
        <div className="coursesPlans-price-info">
          <span className="coursesPlans-normal-price">
            Normaal: <span>€{plan.normaal}</span>
          </span>
          <span className="coursesPlans-current-price">
            Nu: <span>€{plan.nu}</span>
          </span>
        </div>
      </div>

      <div
        className={`coursesPlans-course-list ${isExpanded ? 'coursesPlans-course-list-expanded' : ''}`}
      >
        {displayedList.map((item) => (
          <div className="coursesPlans-course-item">
            {item.isAvailable ? (
              <ItemCheck className="coursesPlans-course-list-item-check-icon icon" />
            ) : (
              <Cross className="coursesPlans-course-list-item-cross-icon icon" />
            )}
            <div>
              <a className="coursesPlans-link" href={`#${item.name}`}>
                {item.displayedName}
              </a>
              {item.hasEnglish && (
                <span className="coursesPlans-subtext">
                  English subtitles available
                </span>
              )}
            </div>
          </div>
        ))}
      </div>

      <button
        className="coursesPlans-view-all-btn"
        onClick={() => setExpanded(!isExpanded)}
      >
        Bekijk alle {availableCoursesCount} cursussen{' '}
        {isExpanded ? (
          <ChevronUp className="coursesPlans-arrow-icon icon" />
        ) : (
          <ChevronDown className="coursesPlans-arrow-icon icon" />
        )}
      </button>

      <button
        onClick={() => {
          handleClick(plan.type);
        }}
        className="coursesPlans-access-button"
      >
        ja, ik wil nu toegang
      </button>
    </div>
  );
}
