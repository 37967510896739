import showdown from 'showdown';
import { useRouter } from 'next/router';
import ReactDOMServer from 'react-dom/server';
import React, { useState, useEffect, useMemo } from 'react';

import './SurveyFullForm.scss';
import { getString } from '../../../helpers/styles';
import icons from '../../../shared/assets/icons/redesign';
import {
  openEmailClientWithFormData,
  prefillSurveyForm
} from '../../../helpers/form';

const { ChevronLeft, Check } = icons;

const converter = new showdown.Converter();

export default function SurveyForm({
  user,
  formAnswer: { xuid, config, form },
  preview,
  returnTo,
  alternativeView,
  updateFormAnswer,
  submitFormAnswer,
  showMessagePopup
}) {
  const router = useRouter();
  const [currentPage, setCurrentPage] = useState(1);
  const [completed, setCompleted] = useState(false);

  const [Survey, setSurvey] = useState(null);
  const [model, setModel] = useState(null);
  const [isLastPage, setIsLastPage] = useState(false);

  const pageCount = getPageCount(model);

  const parsed = JSON.parse(config);

  const customHTML = parsed.completedHtml?.nl || parsed.completedHtml;

  async function onComplete(survey) {
    setCompleted(true);

    document.querySelector('.surveyForm-progress-bar').style.display = 'none';
    document.querySelector('.surveyForm-navigation-bar').style.display = 'none';
    document.querySelector('.surveyForm-prev-page-button').style.display =
      'none';

    const header = document.querySelector('.sv_header');

    if (header) header.style.display = 'none';

    window.scrollTo(0, 0);

    openEmailClientWithFormData(form.xuid, survey.data);

    await updateFormAnswer({
      variables: {
        formAnswerXuid: xuid,
        data: JSON.stringify(survey.data)
      }
    });

    submitFormAnswer({
      variables: { formAnswerXuid: xuid }
    });
  }

  useEffect(() => {
    (async () => {
      const Survey = await import('survey-react');

      await import('survey-react/survey.css');

      const model = new Survey.Model(config);

      model.completedHtml =
        customHTML ||
        ReactDOMServer.renderToStaticMarkup(
          alternativeView ? <AlternativeCompletedPage /> : <CompletedPage />
        );

      model.onAfterRenderQuestion.add(function (survey) {
        initialize(survey);

        if (document.querySelector('.sv_q_radiogroup_control_item'))
          Array.prototype.forEach.call(
            document.querySelectorAll('.sv_q_radiogroup_control_item'),
            (item) => {
              const span = document.createElement('span');

              span.innerHTML =
                '<span class="checkmark-content">&#10003;</span>';
              span.className = 'checkmark';

              item.after(span);
            }
          );

        survey.onTextMarkdown.add(function (survey, options) {
          let str = converter.makeHtml(options.text);

          str = str.substring(3);
          str = str.substring(0, str.length - 4);

          options.html = str;
        });

        Array.prototype.forEach.call(
          document.querySelectorAll('.sv_q_checkbox_control_item'),
          (item) => {
            const span = document.createElement('span');

            span.innerHTML = '<span class="checkmark-content">&#10003;</span>';
            span.className = 'checkmark';

            item.after(span);
          }
        );

        const previewForm = document.querySelector('.surveyForm-preview');

        if (previewForm) {
          Array.prototype.forEach.call(
            previewForm.querySelectorAll('input, button'),
            (item) => {
              item.onclick = () =>
                showMessagePopup({
                  message: 'Dit is een preview versie van het formulier',
                  buttonLabel: 'Terug'
                });
            }
          );
        }
      });

      prefillSurveyForm(form?.xuid, model, user);

      setModel(model);
      setSurvey(Survey);
    })();
  }, []);

  return (
    <div
      className={getString([
        {
          value: 'surveyForm',
          condition: true
        },
        {
          value: 'surveyForm-preview',
          condition: preview
        },
        {
          value: 'surveyForm-alternative-view',
          condition: alternativeView
        }
      ])}
    >
      {preview && (
        <p className="surveyForm-preview-text">
          Dit is een preview versie van het formulier
        </p>
      )}

      <ChevronLeft
        onClick={() => {
          if (model.currentPageNo === 0) {
            router.push(returnTo || '/dashboard');
          } else model.prevPage();
        }}
        className="surveyForm-prev-page-button"
      />

      <p className="surveyForm-progress-bar">
        <span className="surveyForm-current-page-number">{currentPage}</span>/
        {pageCount}
      </p>

      {completed && customHTML && (
        <button
          className="back-btn"
          onClick={() => (location.href = '/dashboard')}
        >
          <ChevronLeft className="back-btn-icon" />
          Terug
        </button>
      )}

      {model && Survey && (
        <Survey.Survey
          model={model}
          onComplete={onComplete}
          onCurrentPageChanged={initialize}
          onValueChanged={() => setIsLastPage(model.isLastPage)}
        />
      )}

      <div className="surveyForm-navigation-bar">
        {isLastPage ? (
          <button
            className="surveyForm-button surveyForm-complete-button"
            onClick={() => {
              !preview && model.completeLastPage();
            }}
          >
            Verzenden
          </button>
        ) : (
          <button
            className="surveyForm-button surveyForm-next-page-button"
            onClick={() => {
              !preview && model.nextPage();
            }}
          >
            Volgende
          </button>
        )}
      </div>
    </div>
  );

  function initialize(survey) {
    setIsLastPage(survey.isLastPage);

    document.querySelector('.surveyForm-current-page-number').innerText =
      survey.currentPageNo + 1;
  }
}

function CompletedPage() {
  return (
    <div>
      <div className="surveyForm-check-icon-wrap">
        <Check className="surveyForm-check-icon" />
      </div>

      <h3 className="surveyForm-confirmation-title">
        Bedankt voor je antwoorden
      </h3>

      <p className="surveyForm-confirmation-text">
        Je kunt nu terugkeren naar de homepage of het dashboard.
      </p>

      <a href="/" className="surveyForm-form-button">
        Homepage
      </a>

      <a href="/dashboard" className="surveyForm-form-button">
        Dashboard
      </a>
    </div>
  );
}

function AlternativeCompletedPage() {
  return (
    <div>
      <div className="surveyForm-check-icon-wrap">
        <Check className="surveyForm-check-icon" />
      </div>

      <h3 className="surveyForm-confirmation-title">
        Bedankt voor je aanvraag
      </h3>

      <p className="surveyForm-confirmation-text">
        Op dit moment testen we de interesse in het huren van de
        babyuitzetlijst. We zullen je op de hoogte brengen als deze functie
        beschikbaar is.
      </p>

      <a
        href="/duurzaam/babyuitzetlijst"
        className="surveyForm-form-button surveyForm-form-button-alternative"
      >
        Naar duurzaamheid
      </a>
    </div>
  );
}

function getPageCount(model) {
  if (!model) return;

  if (model.visiblePageCount < model.PageCount)
    return model.visiblePageCount + 1;
  else return model.PageCount;
}
