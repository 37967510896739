import { useRouter } from 'next/router';

import './UserExpertList.scss';
import BackButton from '../../BackButton/BackButton.jsx';
import icons from '../../../shared/assets/icons/redesign';

const { Info } = icons;

export default function UserExpertList({ list }) {
  const router = useRouter();

  return (
    <div className="userExpertList">
      <BackButton />

      <h2 className="userExpertList-title">Deskundigen in jouw buurt</h2>

      <p className="userExpertList-message">
        <Info className="userExpertList-message-icon" />
        Deskundigen die hier worden getoond zijn partners van
        zwangerenportaal.nl aangedragen door jouw verloskundige praktijk.
      </p>

      <ul className="userExpertList-content">
        {list.map(({ content: { title, subTitle, imageUrl, uri } }) => (
          <li className="userExpertList-content-item">
            <img
              alt="expert-image"
              className="userExpertList-content-item-image"
              src={imageUrl}
            />

            <div>
              <h4 className="userExpertList-content-item-title">{title}</h4>
              <h5 className="userExpertList-content-item-subtitle">
                {subTitle}
              </h5>
            </div>

            <button
              onClick={() => router.push(uri)}
              className="userExpertList-content-item-button"
              href={uri}
            >
              Bekijken
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
