import React from 'react';

import './InsuranceOfferingBanner.scss';

export default function InsuranceOfferingBanner(props) {
  return (
    <div className="insuranceOfferingBanner">
      <h1 className="insuranceOfferingBanner-title">
        Verzekerings-check
      </h1>

      <div className="insuranceOfferingBanner-body">
        <img
          alt="insurance-offering"
          src="https://upr.io/A3gRQA.webp"
          className="insuranceOfferingBanner-img"
        />

        <p className="insuranceOfferingBanner-text-regular">
          Plan simpel een gratis verzekerings-check.
        </p>
        <p className="insuranceOfferingBanner-text-small">
          Bespaar tot €500 per jaar
        </p>
      </div>
    </div>
  );
}
