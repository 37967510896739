import React, { useState, useEffect } from 'react';

import './EmailVerification.scss';
import Popup from '../Popup/Popup.jsx';
import { getString } from '../../../helpers/styles';
import { scrollTop } from '../../../helpers/scroll';
import icons from '../../../shared/assets/icons/redesign';

const { EmailCheck, Edit, Gmail, Outlook } = icons;

export default function EmailVerification() {
  const [popup, showPopup] = useState(false);
  const [userEmail, setUserEmail] = useState('e.v.ebbing@gmail.com');
  const [updatedEmail, setUpdatedEmail] = useState(userEmail);

  useEffect(() => {
    scrollTop();
  }, [popup])

  return (
    <>
      <div className="emailVerification">
        <div className="emailVerification-content-center">
          <div className="emailVerification-icon-wrap">
            <EmailCheck className="emailVerification-icon" />
          </div>

          <h3 className="emailVerification-title text-center">
            Verifieer je e-mailadres
          </h3>

          <p className="emailVerification-text text-center">
            We hebben een e-mail verstuurd naar:<br />

            <span
              className="emailVerification-text-link"
              onClick={() => showPopup(true)}
            >
              {userEmail} <Edit className="emailVerification-text-icon" />
            </span>
          </p>

          <p className="emailVerification-text text-center">
            Klik op de link in de e-mail om je e-mailadres te verifiëren
          </p>

          <div className="emailVerification-button-wrap">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="emailVerification-button
                emailVerification-button-secondary"
              href="https://mail.google.com/"
            >
              <Gmail className="emailVerification-button-icon" /> Open Gmail
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              className="emailVerification-button
                emailVerification-button-secondary"
              href="https://outlook.live.com/owa"
            >
              <Outlook className="emailVerification-button-icon" /> Open Outlook
            </a>
          </div>
        </div>

        <button
          className="emailVerification-button emailVerification-button-secondary"
          onClick={() => {}}
        >
          Stuur e-mail opnieuw
        </button>
      </div>

      {popup &&
        <div className="popup-wrapper">
          <Popup>
            <>
              <div className="popup-header">
                <h4>
                  Wijzig je e-mailadres
                </h4>

                <button
                  className="popup-close-button"
                  onClick={() => showPopup(false)}
                >
                  &times;
                </button>
              </div>

              <div className="popup-body">
                <label className="emailVerification-label">
                  E-mailadres

                  <input
                    type="email"
                    value={updatedEmail}
                    placeholder="Jouw e-mailadres"
                    className="emailVerification-input"
                    onChange={e => setUpdatedEmail(e.target.value)}
                  />
                </label>

                <button
                  onClick={() => showPopup(false)}
                  className="popup-button popup-button-secondary">

                  Annuleren
                </button>

                <button
                  className={getString([{
                    value: 'popup-button popup-button-primary',
                    condition: true
                  }, {
                    value: 'popup-button-disabled',
                    condition: !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                      .test(updatedEmail)
                  }])}
                  onClick={() => (setUserEmail(updatedEmail), showPopup(false))}
                >
                  Wijzigen
                </button>
              </div>
            </>
          </Popup>
        </div>}
    </>
  );
}
