import { useRouter } from 'next/router';
import React, { useRef, useState, useEffect } from 'react';
import { trackAction, usePlatform } from '../../../helpers/tracking';

import './UserSettings.scss';
import Popup from '../Popup/Popup.jsx';
import Portal from '../../Portal/Portal.jsx';
import { isEmail } from '../../../helpers/form';
import icons from '../../../shared/assets/icons';
import { getString } from '../../../helpers/styles';
import BackButton from '../../BackButton/BackButton.jsx';
import DatePickerComponent from '../DatePicker/DatePicker.jsx';
import redesignedIcons from '../../../shared/assets/icons/redesign';
import SurveyQuickForm from '../SurveyQuickForm/SurveyQuickForm.jsx';

const { Check, Search, LoadMore, Info } = redesignedIcons;
const { Exclamation, RightArrow } = icons;

const userStatus = {
  PREGNANT: 'Nu zwanger',
  DELIVERED: 'Bevallen',
  ENDED: 'Zwangerschap vroegtijdig beëindigd',
  PREPARING: 'Voorbereiding op zwangerschap',
  OTHER: 'Anders'
};

const CONTACT_FORM_XUID = 'KcX8pGPFQ-aRo5QecZ2maQ';

export default function UserSettings({
  user,
  loadForm,
  formData,
  organizations,
  addFormAnswer,
  changeUserData,
  submitFormAnswer,
  searchOrganizations,
  organizationsLoading
}) {
  const router = useRouter();
  const platform = usePlatform();

  const hash = router.asPath.split('#')[1];

  const [data, setData] = useState({
    edd: user.edd,
    email: user.email,
    status: user.status,
    mobile: user.mobile,
    provider: user.provider,
    emailPartner: user.emailPartner,
    pregnancyEndDate: user.pregnancyEndDate
  });

  const [label, date, period] = getDateAttributes(data);

  const postCodeInput = useRef();
  const providerEmailField = useRef();

  const [provider, setProvider] = useState(null);
  const [postCode, setPostCode] = useState(user.postcode);
  const [providerListSize, setProviderListSize] = useState(4);

  const [emailPartnerPopup, showEmailPartnerPopup] = useState(false);

  const [initialDate, changeInitialDate] = useState(
    date && {
      day: new Date(date).getDate(),
      year: new Date(date).getFullYear(),
      month: new Date(date).getMonth() + 1
    }
  );

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });

    if (['email', 'phone'].includes(hash))
      loadForm({ variables: { xuid: CONTACT_FORM_XUID } });
  }, [hash, loadForm]);

  switch (hash) {
    case 'status':
      return (
        <div className="userSettings">
          <BackButton />

          <h3 className="userSettings-section-title">Zwangerschap</h3>

          <p className="userSettings-field-title">Huidige Status</p>

          <div className="userSettings-field userSettings-field-disabled">
            {userStatus[data.status]}
          </div>

          <UserSettingsDateItem
            data={data}
            date={date}
            period={period}
            setData={setData}
            platform={platform}
            initialDate={initialDate}
            changeUserData={changeUserData}
            changeInitialDate={changeInitialDate}
          />

          <p className="userSettings-field-title">Status aanpassen naar</p>

          <ul className="userSettings-field-list">
            {Object.values(userStatus).map((val) => (
              <li
                onClick={(e) => {
                  const status = e.target.dataset.id;

                  trackAction(platform, 'settings', 'setstatus');

                  changeUserData({
                    variables: {
                      status,
                      edd: null,
                      pregnancyEndDate: null
                    }
                  });

                  changeInitialDate(null);

                  setData({
                    ...data,
                    edd: null,
                    pregnancyEndDate: null,
                    status
                  });
                }}
                className={getString([
                  {
                    value: 'userSettings-field-list-item',
                    condition: true
                  },
                  {
                    value: 'userSettings-field-list-item-active',
                    condition: val === userStatus[data.status]
                  }
                ])}
                data-id={Object.keys(userStatus).find(
                  (key) => userStatus[key] === val
                )}
                key={Object.keys(userStatus).find(
                  (key) => userStatus[key] === val
                )}
              >
                {val}
                {val === userStatus[data.status] && (
                  <Check className="userSettings-field-list-item-active-icon" />
                )}
              </li>
            ))}
          </ul>
        </div>
      );

    case 'status-date':
      return (
        <div className="userSettings">
          <BackButton />

          <h3 className="userSettings-section-title">Instellingen</h3>

          <UserSettingsDateItem
            data={data}
            date={date}
            period={period}
            setData={setData}
            platform={platform}
            initialDate={initialDate}
            changeUserData={changeUserData}
            changeInitialDate={changeInitialDate}
          />
        </div>
      );

    case 'email':
      return (
        <div className="userSettings">
          <BackButton />

          <h3 className="userSettings-section-title">E-mailadres aanpassen</h3>

          <p className="userSettings-field-title">
            Stuur een mail met de volgende gegevens
          </p>

          {formData && (
            <>
              <SurveyQuickForm
                user={user}
                item={formData.form}
                addFormAnswer={addFormAnswer}
                submitFormAnswer={submitFormAnswer}
              />

              <a
                onClick={() => trackAction(platform, 'settings', 'sendemail')}
                href="mailto:support@zwangerenportaal.nl?subject=E-mailadres aanpassen"
                className="userSettings-button"
              >
                Versturen
              </a>
            </>
          )}
        </div>
      );

    case 'phone':
      return (
        <div className="userSettings">
          <BackButton />

          <h3 className="userSettings-section-title">
            Telefoonnummer aanpassen
          </h3>

          <p className="userSettings-field-title">
            Stuur een mail met de volgende gegevens
          </p>

          {formData && (
            <>
              <SurveyQuickForm
                user={user}
                item={formData.form}
                addFormAnswer={addFormAnswer}
                submitFormAnswer={submitFormAnswer}
              />

              <a
                onClick={() => trackAction(platform, 'settings', 'sendemail')}
                href="mailto:support@zwangerenportaal.nl?subject=Telefoonnummer aanpassen"
                className="userSettings-button"
              >
                Versturen
              </a>
            </>
          )}
        </div>
      );

    case 'partner-email':
      return (
        <div className="userSettings">
          <BackButton />

          <h3 className="userSettings-section-title">
            E-mailadres partner aanpassen
          </h3>

          <p className="userSettings-field-title">E-mailadres partner</p>

          <div
            className="
            userSettings-field
            userSettings-popup-field
            userSettings-field-disabled
          "
          >
            {data.emailPartner || 'Onbekend'}
          </div>

          <button
            className="userSettings-button"
            onClick={() => showEmailPartnerPopup(true)}
          >
            Wijzigen
          </button>

          {emailPartnerPopup && (
            <Portal>
              <Popup>
                <>
                  <div className="popup-header">
                    <h4>Wijzig het e-mailadres</h4>

                    <button
                      className="popup-close-button"
                      onClick={() => showEmailPartnerPopup(false)}
                    >
                      &times;
                    </button>
                  </div>

                  <div className="popup-body userSettings-popup-body">
                    <label className="userSettings-field-title userSettings-popup-field-title">
                      E-mailadres partner
                      <input
                        ref={providerEmailField}
                        autoFocus
                        className="userSettings-provider-email-field"
                        type="email"
                        onChange={() =>
                          providerEmailField.current.classList.contains(
                            'userSettings-popup-field-warning'
                          ) &&
                          providerEmailField.current.classList.remove(
                            'userSettings-popup-field-warning'
                          )
                        }
                        defaultValue={data.emailPartner}
                      />
                    </label>

                    <button
                      onClick={() => {
                        trackAction(platform, 'settings', 'clearpartneremail');

                        setData({
                          ...data,
                          emailPartner: null
                        });

                        changeUserData({
                          variables: {
                            emailPartner: null
                          }
                        });

                        showEmailPartnerPopup(false);
                      }}
                      className="userSettings-button userSettings-button-secondary"
                    >
                      Verwijderen
                    </button>

                    <button
                      onClick={() => {
                        if (!isEmail(providerEmailField.current.value)) {
                          return providerEmailField.current.classList.add(
                            'userSettings-popup-field-warning'
                          );
                        } else {
                          trackAction(platform, 'settings', 'setpartneremail');

                          setData({
                            ...data,
                            emailPartner: providerEmailField.current.value
                          });

                          changeUserData({
                            variables: {
                              emailPartner: providerEmailField.current.value
                            }
                          });

                          showEmailPartnerPopup(false);
                        }
                      }}
                      className="userSettings-button"
                    >
                      Wijzigen
                    </button>
                  </div>
                </>
              </Popup>
            </Portal>
          )}
        </div>
      );

    case 'provider':
      return (
        <div className="userSettings">
          <BackButton />

          <h3 className="userSettings-provider-title">Kies je zorgverlener</h3>

          <h5 className="userSettings-provider-subtitle">
            Door je account te koppelen aan je zorgverlener houden we je op de
            hoogte van je voortgang en kan je makkelijk contact met hen opnemen
          </h5>

          <form
            className="userSettings-provider-search-form"
            onSubmit={(e) => {
              e.preventDefault();
              searchOrganizations({
                variables: {
                  postalcode: e.target.code.value,
                  distance: 1000
                }
              });
            }}
          >
            <input
              name="code"
              type="number"
              pattern="[0-9]+"
              value={postCode}
              ref={postCodeInput}
              onKeyPress={(e) => !/[0-9]/.test(event.key) && e.preventDefault()}
              onChange={(e) =>
                e.target.value.length <= 4 && setPostCode(e.target.value)
              }
              placeholder="Zoek op postcode"
              className="userSettings-provider-input userSettings-provider-search-input"
            />

            <Search
              onClick={() => {
                searchOrganizations({
                  variables: {
                    postalcode: postCodeInput.current.value,
                    distance: 1000
                  }
                });
              }}
              className="userSettings-provider-search-icon"
            />

            <button
              className="userSettings-button userSettings-provider-search-button"
              onClick={() => {
                trackAction(platform, 'settings', 'searchprovider');

                setProvider(null);

                searchOrganizations({
                  variables: {
                    postalcode: postCodeInput.current.value,
                    distance: 1000
                  }
                });
              }}
            >
              Zoeken
            </button>
          </form>

          {organizationsLoading && (
            <p className="userSettings-provider-info-message">Laden...</p>
          )}

          {organizations && !organizations?.length && (
            <p className="userSettings-provider-info-message">
              <Info className="userSettings-provider-info-message-icon" />
              We hebben geen zorgverleners kunnen vinden binnen deze postcode
            </p>
          )}

          {!!organizations?.length && (
            <>
              <p className="userSettings-provider-info-message">
                <span className="userSettings-provider-text-bold">
                  {organizations?.length} zorgverleners&nbsp;
                </span>
                bij jou in de buurt:
              </p>

              <ul className="userSettings-provider-list">
                {organizations.slice(0, providerListSize).map((item) => (
                  <li
                    key={item.id}
                    className={getString([
                      {
                        value: 'userSettings-provider-list-item',
                        condition: true
                      },
                      {
                        value: 'userSettings-provider-list-item-active',
                        condition: provider?.accountName === item.accountName
                      }
                    ])}
                  >
                    <label>
                      <input
                        type="radio"
                        onChange={() => {}}
                        className="userSettings-provider-radio-input"
                        onClick={() => setProvider(item)}
                        checked={provider?.accountName === item.accountName}
                      />

                      <span
                        className="userSettings-provider-checkmark
                          userSettings-provider-checkmark"
                      >
                        <Check className="userSettings-provider-checkmark-icon" />
                      </span>

                      <OrganizationCard
                        logoUrl={item.logoUrl}
                        name={item.displayName}
                        distance={item.distance}
                      />
                    </label>
                  </li>
                ))}
              </ul>

              {providerListSize < organizations.length && (
                <button
                  onClick={() => {
                    trackAction(platform, 'settings', 'loadmoreproviders');

                    setProviderListSize(providerListSize + 4);
                  }}
                  className="userSettings-provider-loadmore-button"
                >
                  <LoadMore className="userSettings-provider-loadmore-icon" />
                  Laad meer
                </button>
              )}
            </>
          )}

          <button
            className={getString([
              {
                value: 'userSettings-button userSettings-provider-button',
                condition: true
              },
              {
                value: 'userSettings-button-disabled',
                condition: !provider
              }
            ])}
            onClick={() => {
              if (provider) {
                trackAction(platform, 'settings', 'setprovider');

                changeUserData({
                  variables: {
                    weekMail: 'Y',
                    provider: provider.accountName,
                    providerStatus: 'OK'
                  }
                });

                setData({
                  ...data,
                  provider
                });

                router.push('/profile-settings');
              }
            }}
          >
            Kies zorgverlener
          </button>
        </div>
      );

    default:
      return (
        <div className="userSettings">
          <BackButton />

          <div>
            <h3 className="userSettings-section-title">Instellingen</h3>

            <div className="userSettings-section">
              <h3 className="userSettings-section-title">Zwangerschap</h3>

              <p className="userSettings-field-title">Status</p>

              <UserSettingsItem
                text={userStatus[data.status]}
                onClick={() => router.push('/profile-settings#status')}
              />

              <UserSettingsDateItem
                data={data}
                platform={platform}
                onClick={() => router.push('/profile-settings#status-date')}
              />
            </div>

            <div className="userSettings-section">
              <h3 className="userSettings-section-title">Zorgverlener(s)</h3>

              <p className="userSettings-field-title">Primaire zorgverlener</p>

              <button
                onClick={() => router.push('/profile-settings#provider')}
                className="userSettings-provider-field userSettings-field"
              >
                {data.provider ? (
                  <>
                    <img
                      className="userSettings-provider-field-img"
                      src={data.provider.logoUrl}
                      alt="provider-logo"
                    />
                    <span className="userSettings-provider-field-value">
                      {data.provider.displayName}
                    </span>
                  </>
                ) : (
                  <span className="userSettings-provider-field-value">
                    Onbekend
                  </span>
                )}
                <RightArrow className="userSettings-field-icon" />
              </button>

              {!data.provider && (
                <p className="userSettings-warning">
                  <Exclamation className="userSettings-warning-icon" />
                  Je hebt geen primaire zorgverlener toegevoegd
                </p>
              )}
            </div>

            <div className="userSettings-section">
              <h3 className="userSettings-section-title">Profiel</h3>

              <p className="userSettings-field-title">E-mailadres</p>

              <UserSettingsItem
                onClick={() => router.push('/profile-settings#email')}
                text={
                  data.email ? (
                    <>
                      {data.email.slice(0, 3)}
                      &bull;&bull;&bull;&bull;&bull;&bull;
                    </>
                  ) : (
                    'Onbekend'
                  )
                }
              />

              <p className="userSettings-field-title">Telefoon nummer</p>

              <UserSettingsItem
                onClick={() => router.push('/profile-settings#phone')}
                text={
                  data.mobile ? (
                    <>
                      {data.mobile.slice(0, 4)}
                      &bull;&bull;&bull;&bull;&bull;&bull;
                    </>
                  ) : (
                    'Onbekend'
                  )
                }
              />

              <p className="userSettings-field-title">Accountbeveiliging</p>

              <a
                href="https://id.zwangerenportaal.nl/portal/mfa"
                target="_blank"
                rel="noopener noreferrer"
                className="userSettings-button"
                onClick={() =>
                  trackAction(platform, 'settings', 'updateaccountsecurity')
                }
              >
                Wijzigen
              </a>

              <p className="userSettings-field-title">Wachtwoord</p>

              <a
                target="_blank"
                rel="noopener noreferrer"
                className="userSettings-button"
                href="https://id.zwangerenportaal.nl/password-reset"
                onClick={() =>
                  trackAction(platform, 'settings', 'updatepassword')
                }
              >
                Wijzigen
              </a>

              <p className="userSettings-field-title">E-mail voorkeuren</p>

              <a
                target="_blank"
                rel="noopener noreferrer"
                className="userSettings-button"
                href={user.emailPreferencesUrl}
                onClick={() =>
                  trackAction(platform, 'settings', 'updateemailpreferences')
                }
              >
                Wijzigen
              </a>
            </div>

            <div className="userSettings-section">
              <h3 className="userSettings-section-title">Gezin</h3>

              <p className="userSettings-field-title">E-mail adress partner</p>

              <UserSettingsItem
                onClick={() => router.push('/profile-settings#partner-email')}
                text={data.emailPartner || 'Onbekend'}
              />
            </div>
          </div>
        </div>
      );
  }
}

function UserSettingsItem({ text, onClick }) {
  return (
    <button className="userSettings-field" onClick={onClick}>
      {text}
      <RightArrow className="userSettings-field-icon" />
    </button>
  );
}

function UserSettingsDateItem({
  data,
  date,
  period,
  setData,
  onClick,
  platform,
  initialDate,
  changeUserData,
  changeInitialDate
}) {
  const [datePicker, showDatePicker] = useState(false);

  if (['PREPARING', 'OTHER'].includes(data.status)) return null;

  const [label, value] = getDateAttributes(data);

  return (
    <div className="userSettings-date-field">
      <p className="userSettings-field-title">{label}</p>

      <UserSettingsItem
        onClick={() => {
          if (!data.status) return;

          if (onClick) return onClick();

          if (date)
            changeInitialDate({
              day: new Date(date).getDate(),
              year: new Date(date).getFullYear(),
              month: new Date(date).getMonth() + 1
            });

          showDatePicker(true);
        }}
        text={value ? value.split('-').reverse().join('-') : 'Onbekend'}
      />

      {datePicker && (
        <div className="userSettings-date-picker">
          <DatePickerComponent
            title="Kies de datum"
            period={period}
            initialDate={initialDate}
            showDatePicker={showDatePicker}
            onDateChange={(date) => {
              const formattedDate = formatDate(date);

              const variables =
                data.status === 'PREGNANT'
                  ? { edd: formattedDate }
                  : { pregnancyEndDate: formattedDate };

              trackAction(
                platform,
                'settings',
                data.status === 'PREGNANT' ? 'setedd' : 'setpregnancyenddate'
              );

              changeUserData({
                variables: {
                  ...variables
                }
              });

              setData({
                ...data,
                ...variables
              });
            }}
            changeInitialDate={changeInitialDate}
          />
        </div>
      )}
    </div>
  );
}

function OrganizationCard({ logoUrl, name, distance }) {
  return (
    <div className="userSettings-provider-card">
      <img
        className="userSettings-provider-image"
        width="45"
        height="45"
        src={logoUrl}
        alt="organization-logo"
      />

      <p className="userSettings-provider-card-text">
        {name}

        {distance && (
          <span className="userSettings-provider-card-text-small">
            {distance} km
          </span>
        )}
      </p>
    </div>
  );
}

function getDateAttributes(data) {
  const start = new Date();
  const end = new Date();

  end.setDate(end.getDate() + 40 * 7);
  start.setDate(start.getDate() - 40 * 7);

  switch (data.status) {
    case 'PREGNANT':
      return [
        'Uitgerekende datum',
        data.edd,
        {
          start: new Date(),
          end
        }
      ];
    case 'DELIVERED':
      return [
        'Bevallen op',
        data.pregnancyEndDate,
        {
          start,
          end: new Date()
        }
      ];
    case 'ENDED':
      return [
        'Beëindigd per',
        data.pregnancyEndDate,
        {
          end: new Date()
        }
      ];
    default:
      return [];
  }
}

function formatDate(date) {
  if (!date) return null;

  const offset = date.getTimezoneOffset();

  date = new Date(date.getTime() - offset * 60 * 1000);

  return date.toISOString().split('T')[0];
}
