import React, { useRef, useState, useEffect } from 'react';

import './SignInForm.scss';
import { scrollTop } from '../../../helpers/scroll';

import icons from '../../../shared/assets/icons/redesign';
import ToastNotification from '../ToastNotification/ToastNotification.jsx'

const { Attention, Logo, Eye, Check } = icons;

export default function SignInForm(props) {
  const passwordInput = useRef();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    scrollTop();
  }, [errors])

  return (
    <>
      <div className="signInForm">
        <div className="signInForm-header">
          <Logo />

          <button
            onClick={() => location.href = '/'}
            className="signInForm-close-button">
            &times;
          </button>
        </div>

        <div className="signInForm-body">
          <h3 className="signInForm-title">
            Inloggen
          </h3>

          <h5 className="signInForm-subtitle">
            Welkom bij zwangerenportaal.nl.
            Log in om alle functies te ontdekken
          </h5>

          {errors.response &&
            <p className="signInForm-response-error">
              <Attention /> {errors.response}
            </p>
          }

          <form
            autoComplete="off"
            onSubmit={e => signIn(e)}
          >
            <label className="signInForm-label">
              Gebruikersnaam

              {errors.username &&
                <p className="signInForm-response-error">
                  <Attention /> {errors.username}
                </p>
              }

              <input
                autoFocus
                name="username"
                className="signInForm-input"
                placeholder="Jouw gebruikersnaam"
              />
            </label>

            <label className="signInForm-label">
              Wachtwoord

              {errors.password &&
                <p className="signInForm-response-error">
                  <Attention /> {errors.password}
                </p>
              }

              <input
                type="password"
                name="password"
                ref={passwordInput}
                className="signInForm-input"
                placeholder="Jouw wachtwoord"
              />

              <Eye
                className="signInForm-eye-icon"
                onClick={() => {
                  if (passwordInput.current.type === 'password')
                    passwordInput.current.type = 'text';
                  else passwordInput.current.type = 'password';
                }}
              />
            </label>

            <label className="signInForm-label">
              <input
                className="signInForm-checkbox-input"
                type="checkbox"
              />

              <span className="signInForm-checkbox-label">
                Onthoud mij voor 15 dagen
              </span>

              <span className="signInForm-checkmark">
                <Check className="signInForm-checkmark-icon" />
              </span>
            </label>

            <button
              type="submit"
              className="signInForm-button signInForm-button-primary"
            >
              Inloggen
            </button>
          </form>

          <div className="signInForm-footer">
            <p className="signInForm-footer-text">
              <a
                className="signInForm-link"
                href={props.retrieveUsernameUrl}>Gebruikersnaam
              </a> of <a
                className="signInForm-link"
                href={props.passwordResetUrl}>Wachtwoord
              </a> vergeten?
            </p>

            <p className="signInForm-footer-text">
              Heb je nog geen account? <a
                className="signInForm-link"
                href={props.signupUrl}>Registreren
              </a>
            </p>
          </div>
        </div>
      </div>

      {props.showNotification &&
        <ToastNotification>
          <div className="toastNotification-icon-wrap">
            <Check className="toastNotification-icon" />
          </div>

          <p className="toastNotification-text">
            Je e-mailadres & telefoonnummer zijn succesvol geverifieerd!
          </p>

          <button
            className="toastNotification-close-button"
            onClick={e => e.target.closest('.toastNotification')
              .classList.remove('toastNotification-show')}
          >
            &times;
          </button>
        </ToastNotification>}
    </>
  );

  function signIn(e) {
    e.preventDefault();

    setErrors({
      username: null,
      password: null,
      response: errors.response
    });

    const { username, password } = e.target;

    if (!username.value || !password.value) {
      return setErrors({
        ...(!username.value &&
          { username: 'Je gebruikersnaam is verplicht' }),
        ...(!password.value &&
          { password: 'Je wachtwoord is verplicht' })
      });
    }

    const xhr = new XMLHttpRequest();

    xhr.open('POST', props.authUrl);

    xhr.send(JSON.stringify({
      username: username.value,
      password: password.value
    }));

    xhr.onload = () => {
      if (xhr.status === 200) {
        const jwt = JSON.parse(xhr.response).jwt;

        const date = new Date();

        date.setTime(date.getTime() + (3 * 24 * 60 * 60 * 1000));

        const expires = `expires=${date.toUTCString()}`;

        document.cookie = `jwt=${jwt};${expires};path=/`;

        location.href = props.redirectUrl || 'dashboard';
      } else {
        setErrors({
          response: `De gebruikersnaam of het wachtwoord
            dat u hebt ingevoerd is ongeldig`
        });
      }
    };

    xhr.onerror = () => setErrors({ response: 'Verbindingsfout' });
  }
}
