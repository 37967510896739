import React, { Fragment } from 'react';

import './DashboardContent.scss';
import sendEvent from '../../../helpers/tracking';
import { useMobile } from '../../../helpers/hooks';
import ArticleList from '../ArticleList/ArticleList.jsx';
import icons from '../../../shared/assets/icons/redesign';

import SurveyQuickForm from '../SurveyQuickForm/SurveyQuickForm.jsx';
import NotificationBanner from '../NotificationBanner/NotificationBanner.jsx';

const { Settings } = icons;

export default function DashboardContent({
  user,
  NextLink,
  Component,
  addUserEvent,
  addFormAnswer,
  quickFormList,
  notificationList,
  submitFormAnswer,
  showMessagePopup,
  todoContentByPhase,
  channelContentList = []
}) {
  const [isMobile] = useMobile();

  return (
    <section className="dashboardContent">
      <div className="dashboardContent-main">
        <Component
          user={{ ...user, provider: user.provider || {} }}
          NextLink={NextLink}
          addFormAnswer={addFormAnswer}
          quickFormList={quickFormList}
          submitFormAnswer={submitFormAnswer}
          notificationList={notificationList}
          showMessagePopup={showMessagePopup}
          todoContentByPhase={todoContentByPhase}
        />
      </div>

      <div className="dashboardContent-sidebar">
        <div className="dashboard-settings-button-wrap">
          <NextLink href="/dashboard/profile">
            <button
              onClick={() =>
                isMobile
                  ? sendEvent('dashboard-mobile', 'dashboard-mobile-settings')
                  : sendEvent('dashboard-desktop', 'dashboard-desktop-settings')
              }
              className="dashboard-settings-button"
            >
              <Settings className="dashboard-settings-icon" />

              <p className="dashboard-settings-button-label">Instellingen</p>
            </button>
          </NextLink>
        </div>

        {notificationList.map((item, idx) => (
          <NotificationBanner item={item} key={idx} NextLink={NextLink} />
        ))}

        {quickFormList.map((item, idx) => (
          <SurveyQuickForm
            key={idx}
            user={user}
            item={item}
            addUserEvent={addUserEvent}
            addFormAnswer={addFormAnswer}
            submitFormAnswer={submitFormAnswer}
          />
        ))}

        <br />

        <ArticleList list={channelContentList} NextLink={NextLink} />
      </div>
    </section>
  );
}
