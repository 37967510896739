import { useRef, useState } from 'react';

import './TodoContent.scss';
import { getString } from '../../helpers/styles';
import icons from '../../shared/assets/icons/redesign';

const { ChevronRight, Check } = icons;

export default function TodoContent({
  id,
  link,
  title,
  useAddUserEventMutation,
  dashboardTodoContentClickData
}) {
  const addUserEvent = useAddUserEventMutation();

  const box = useRef();
  const checkbox = useRef();
  const [startTransition, setStartTransition] = useState(false);

  const clickedBefore = (dashboardTodoContentClickData || {})[`item_${id}`]
    ?.hasEvent;

  return (
    !clickedBefore && (
      <div
        ref={box}
        onTransitionEnd={() => {
          addUserEvent({
            variables: {
              type: `dashboard-todo-content-id-${id}-click`,
              data: '{}'
            }
          });
        }}
        className="_mobile cursor-pointer todoContent-wrap"
        onClick={(e) => {
          if (e.target.closest('.todoContent-checkbox')) return;
          location.href = link;
        }}
      >
        <div className="todoContent">
          <span
            ref={checkbox}
            onClick={(e) => {
              setStartTransition(true);

              box.current.classList.add('todoContent-wrap-transition');
            }}
            className={getString([
              {
                value: 'todoContent-checkbox',
                condition: true
              },
              {
                value: 'todoContent-checkbox-active',
                condition: startTransition
              }
            ])}
          >
            <Check className="todoContent-checkbox-icon" />
          </span>

          <p className="todoContent-title">To-do: {title}</p>

          <ChevronRight className="todoContent-icon" />
        </div>
      </div>
    )
  );
}
