import gql from 'graphql-tag';
import { useRouter } from 'next/router';

import './DashboardCategoryList.scss';
import sendEvent from '../../../helpers/tracking';
import { useMobile } from '../../../helpers/hooks';
import { getString } from '../../../helpers/styles';
import icons from '../../../shared/assets/icons/redesign';
import { getWeekContentLink } from '../../../helpers/userWeek';
import {
  isOther,
  isPartner,
  isPregnant,
  isDelivered,
  defaultCoursesPage,
  hasProviderFilesAccess
} from '../../../helpers/user';
import SearchBar from '../SearchBar/SearchBar.jsx';
import TodoContent from '../../TodoContent/TodoContent.jsx';
import SurveyQuickForm from '../SurveyQuickForm/SurveyQuickForm';
import NotificationBanner from '../NotificationBanner/NotificationBanner.jsx';
import { retrieveProviderChannelLink } from '../PersonalDashboardBanner/PersonalDashboardBanner';

const {
  Faq,
  Chat,
  Video,
  Folder,
  Forum,
  Message,
  Medical,
  Calendar,
  Checkbox,
  FileEdit,
  Discount,
  Magazine,
  Community,
  ExpertLocation,
  CalendarSimple,
  CoursesDashboard
} = icons;

const trackingCodes = {
  magazine: {
    desktop: ['dashboard-desktop', 'dashboard-desktop-providerchannel'],
    mobile: ['dashboard-mobile', 'dashboard-mobile-providerchannel']
  },
  files: {
    desktop: ['dashboard-desktop', 'dashboard-desktop-mijndossier'],
    mobile: ['dashboard-mobile', 'dashboard-mobile-mijndossier']
  },
  appointments: {
    desktop: ['dashboard-desktop', 'dashboard-desktop-afspraken'],
    mobile: ['dashboard-mobile', 'dashboard-mobile-afspraken']
  },
  sharedFolders: {
    desktop: ['dashboard-desktop', 'dashboard-desktop-bestanden'],
    mobile: ['dashboard-mobile', 'dashboard-mobile-bestanden']
  },
  videoCall: {
    desktop: ['dashboard-desktop', 'dashboard-desktop-videoconsult'],
    mobile: ['dashboard-mobile', 'dashboard-mobile-videoconsult']
  },
  rooms: {
    desktop: ['dashboard-desktop', 'dashboard-desktop-chat'],
    mobile: ['dashboard-mobile', 'dashboard-mobile-chat']
  },
  experts: {
    desktop: ['dashboard-desktop', 'dashboard-desktop-deskundigen'],
    mobile: ['dashboard-mobile', 'dashboard-mobile-deskundigen']
  },
  settings: {
    desktop: ['dashboard-mobile', 'dashboard-mobile-settings'],
    mobile: ['dashboard-desktop', 'dashboard-desktop-settings'],
    partnerDesktop: [
      'dashboard-partner-desktop',
      'dashboard-partner-desktop-settings'
    ],
    partnerMobile: [
      'dashboard-partner-mobile',
      'dashboard-partner-mobile-settings'
    ]
  },
  week: {
    partnerDesktop: [
      'dashboard-partner-desktop',
      'dashboard-partner-desktop-mijnweek'
    ],
    partnerMobile: [
      'dashboard-partner-mobile',
      'dashboard-partner-mobile-mijnweek'
    ]
  },
  todo: {
    desktop: ['dashboard-desktop', 'dashboard-desktop-todo'],
    mobile: ['dashboard-mobile', 'dashboard-mobile-todo'],
    partnerDesktop: [
      'dashboard-partner-desktop',
      'dashboard-partner-desktop-todo'
    ],
    partnerMobile: ['dashboard-partner-mobile', 'dashboard-partner-mobile-todo']
  },
  offer: {
    partnerDesktop: [
      'dashboard-partner-desktop',
      'dashboard-partner-desktop-aanbiedingen'
    ],
    partnerMobile: [
      'dashboard-partner-mobile',
      'dashboard-partner-mobile-aanbiedingen'
    ]
  }
};

export default function DashboardCategoryList({
  user,
  NextLink,
  quickFormList,
  addFormAnswer,
  notificationList,
  submitFormAnswer,
  dashboardTodoContentClickData,
  useQuery,
  addUserEvent,
  showMessagePopup,
  todoContentByPhase = [],
  useAddUserEventMutation
}) {
  if (!user.provider) user.provider = {};

  const providerChannelLink = retrieveProviderChannelLink(user);

  const router = useRouter();
  const [isMobile] = useMobile();

  const isPartnerUser = isPartner(user);

  const trackingKey = isPartnerUser
    ? isMobile
      ? 'partnerMobile'
      : 'partnerDesktop'
    : isMobile
    ? 'mobile'
    : 'desktop';

  const { data = {} } = useQuery(gql`
      query {
          me {
              ${[
                'sporten',
                'voeding',
                'slaap',
                'financieel',
                'duurzaamheid',
                'welzijn',
                'werk'
              ].map((item) => {
                return `${item}: hasEvent(type: "dashboard-${item}-category-click")`;
              })}

          }
      }
  `);

  const pregUserList = [
    {
      background: 'https://upr.io/Px9Wxd.webp',
      title: 'Sporten',
      onClick: () =>
        handleClick('sporten', {
          introduction: 'sporten-en-zwanger-introductie',
          category: 'sporten-tijdens-de-zwangerschap'
        })
    },
    {
      background: 'https://upr.io/P2BXrP.webp',
      title: 'Voeding',
      onClick: () =>
        handleClick('voeding', {
          introduction: 'voeding-en-zwanger-introductie',
          category: 'eten-tijdens-de-zwangerschap'
        })
    },
    {
      background: 'https://upr.io/pj9kqd.webp',
      title: 'Slaap',
      onClick: () =>
        handleClick('slaap', {
          introduction: 'slaap-zwangerschap-introductie',
          category: 'slapen-zwangerschap'
        })
    },
    {
      background: 'https://upr.io/dyZwgp.webp',
      title: 'Financieel',
      onClick: () =>
        handleClick('financieel', {
          introduction: 'financien-en-zwanger-introductie',
          category: 'financien'
        })
    },
    {
      background: 'https://upr.io/db7QRp.webp',
      title: 'Duurzaamheid',
      onClick: () =>
        handleClick('duurzaamheid', {
          introduction: 'duurzaamheid-introductie',
          category: 'duurzaamheid-ouderschap'
        })
    },
    {
      background: 'https://upr.io/P9qgkP.webp',
      title: 'Welzijn',
      onClick: () =>
        handleClick('welzijn', {
          introduction: 'welzijn-introductie',
          category: 'ontspanning-tijdens-de-zwangerschap'
        })
    },
    {
      background: 'https://upr.io/ANr2DA.webp',
      title: 'Werk',
      onClick: () =>
        handleClick('werk', {
          introduction: 'werk-zwangerschap-introductie',
          category: 'werken-tijdens-de-zwangerschap'
        })
    }
  ];

  const deliveredUserList = [
    {
      background: 'https://upr.io/Px9Wxd.webp',
      title: 'Sporten',
      link: '/herstel'
    },
    {
      link: '/voeding',
      background: 'https://upr.io/PryRlA.webp',
      title: 'voeding'
    },
    {
      link: '/slaap',
      background: 'https://upr.io/PGQzGp.webp',
      title: 'Slaap'
    },
    {
      link: '/financien',
      background: 'https://upr.io/dyZwgp.webp',
      title: 'Financieel'
    },
    {
      link: '/welzijn-postpartum',
      background: 'https://upr.io/P9qgkP.webp',
      title: 'Mentale gezondheid'
    },
    {
      link: '/opvoeden',
      background: 'https://upr.io/p88XWp.webp',
      title: 'Opvoeding'
    },
    {
      link: '/duurzaamheid-ouderschap',
      background: 'https://upr.io/db7QRp.webp',
      title: 'Duurzaamheid'
    },
    {
      link: '/werk-ouderschap',
      background: 'https://upr.io/ANr2DA.webp',
      title: 'Werk'
    }
  ];

  const topList = isPartnerUser
    ? [
        {
          link: getWeekContentLink(user),
          Icon: CalendarSimple,
          title: 'Mijn week',
          onClick: () => sendEvent(...trackingCodes.week[trackingKey])
        },
        {
          link: '/zwanger/to-do-partner',
          Icon: Checkbox,
          title: 'To-do’s',
          onClick: () => {
            sendEvent(...trackingCodes.todo[trackingKey]);
          }
        },
        {
          Icon: Chat,
          title: 'Forum',
          disabled: true
        }
      ]
    : [
        providerChannelLink && {
          link: providerChannelLink,
          Icon: Magazine,
          title: user.provider.displayName,
          onClick: () => sendEvent(...trackingCodes.magazine[trackingKey])
        },
        {
          link: '/files',
          Icon: FileEdit,
          title: 'Mijn dossier',
          featureName: 'dossierFeature',
          onClick: () => sendEvent(...trackingCodes.files[trackingKey])
        },
        {
          link: '/appointments',
          Icon: Calendar,
          title: 'Mijn afspraken',
          featureName: 'appointmentsFeature',
          onClick: () => sendEvent(...trackingCodes.appointments[trackingKey])
        },
        {
          link: '/shared-folders',
          Icon: Folder,
          title: 'Bestanden',
          featureName: 'filesFeature',
          ...(!hasProviderFilesAccess(user) && {
            disabled: true
          }),
          onClick: () => sendEvent(...trackingCodes.sharedFolders[trackingKey])
        },
        {
          link: '/video-call',
          Icon: Video,
          title: 'Video consult',
          featureName: 'videoFeature',
          onClick: () => sendEvent(...trackingCodes.videoCall[trackingKey])
        },
        {
          link: '/rooms',
          Icon: Message,
          title: 'Chat',
          featureName: 'chatFeature',
          onClick: () => sendEvent(...trackingCodes.rooms[trackingKey])
        },
        {
          link: '/deskundigen-in-de-buurt',
          Icon: ExpertLocation,
          title: 'Deskundigen',
          onClick: () => sendEvent(...trackingCodes.experts[trackingKey])
        }
      ].filter(Boolean);

  const middleList = isPregnant(user)
    ? pregUserList
    : isDelivered(user)
    ? deliveredUserList
    : [];

  const bottomList = [
    {
      Icon: CoursesDashboard,
      link: defaultCoursesPage(user),
      title: 'Cursusportaal',
      skipCss: true
    },
    {
      Icon: Community,
      link: '/community/onboarding?groupXuid=null',
      title: 'Community'
    },
    {
      Icon: Medical,
      link: '/verzekeringen',
      title: 'Verzekeringen'
    },
    {
      Icon: Faq,
      link: '/veelgestelde-vragen-faq',
      title: 'FAQ',
      skipCss: true
    },
    {
      Icon: Message,
      link: '/bookmarks',
      title: 'Opgeslagen'
    },
    {
      Icon: Forum,
      link: '/channels',
      title: 'Kanalen'
    },
    {
      Icon: Discount,
      link: '/discount-program-register',
      title: 'Collectieve korting'
    }
  ];

  return (
    <div className="_dashboardCategoryList">
      <div className="_mobile" style={{ marginTop: '20px' }}>
        {notificationList.map((item, idx) => (
          <NotificationBanner item={item} key={idx} NextLink={NextLink} />
        ))}

        {quickFormList.map((item, idx) => (
          <SurveyQuickForm
            key={idx}
            user={user}
            item={item}
            addUserEvent={addUserEvent}
            addFormAnswer={addFormAnswer}
            submitFormAnswer={submitFormAnswer}
          />
        ))}

        {todoContentByPhase
          .filter(({ content }) => content)
          .map(({ id, content: { title, uri } }) => (
            <TodoContent
              id={id}
              key={id}
              link={uri}
              user={user}
              title={title}
              useAddUserEventMutation={useAddUserEventMutation}
              dashboardTodoContentClickData={dashboardTodoContentClickData}
            />
          ))}
      </div>

      <div className="dashboardCategoryList-header">
        <h2 className="dashboardCategoryList-header-title">
          {isPartnerUser ? 'Partner Dashboard' : 'Zorg'}
        </h2>
      </div>

      {isPartnerUser && <SearchBar />}

      <ul className="dashboardCategoryList-content">
        {topList.map(
          (
            { Icon, title, description, link, featureName, onClick, disabled },
            idx
          ) => {
            if (!user.provider?.accountName || isOther(user) || isPartnerUser)
              return (
                <li
                  key={idx}
                  onClick={() => {
                    if (isPartnerUser)
                      if (disabled) return;
                      else return onClick(), (location.href = link);

                    showMessagePopup({
                      message: 'Je bent nog niet gelinkt aan een zorgverlener.',
                      buttonLink: '/profile-settings',
                      buttonLabel: 'Zorgverlener toevoegen'
                    });

                    onClick();
                  }}
                  className={getString([
                    {
                      value: 'dashboardCategoryList-item',
                      condition: true
                    },
                    {
                      value: 'dashboardCategoryList-item--disabled',
                      condition: disabled || !isPartnerUser
                    }
                  ])}
                >
                  <Icon className="dashboardCategoryList-item-icon" />

                  <h3 className="dashboardCategoryList-item-title">{title}</h3>

                  <p className="dashboardCategoryList-item-description">
                    {description}
                  </p>
                </li>
              );

            if ((featureName && !user.provider[featureName]) || disabled)
              return (
                <li
                  key={idx}
                  onClick={() => (
                    showMessagePopup({
                      message: `Helaas, deze functie wordt niet ondersteund
                  door jouw zorgverlener.`,
                      buttonLabel: 'Doorgaan'
                    }),
                    onClick()
                  )}
                  className="
                dashboardCategoryList-item
                dashboardCategoryList-item--disabled"
                >
                  <Icon className="dashboardCategoryList-item-icon" />

                  <h3 className="dashboardCategoryList-item-title">{title}</h3>

                  <p className="dashboardCategoryList-item-description">
                    {description}
                  </p>
                </li>
              );

            return (
              <NextLink href={link} key={idx}>
                <li
                  key={idx}
                  onClick={onClick}
                  className="dashboardCategoryList-item"
                >
                  <Icon className="dashboardCategoryList-item-icon" />

                  <h3 className="dashboardCategoryList-item-title">{title}</h3>

                  <p className="dashboardCategoryList-item-description">
                    {description}
                  </p>
                </li>
              </NextLink>
            );
          }
        )}
      </ul>

      <div>
        {!isPartnerUser && !!middleList.length && (
          <>
            <h2 className="dashboardCategoryList-header-title">Gezondheid</h2>

            <ul className="dashboardCategoryList-content">
              {middleList.map(({ link, title, background, onClick }, idx) => (
                <li
                  key={idx}
                  style={{
                    background: `center/cover no-repeat url("${background}")`,
                    position: 'relative',
                    height: '112px',
                    border: 'none'
                  }}
                  className="dashboardCategoryList-item"
                  onClick={() => {
                    if (onClick) onClick();
                    else router.push(link);
                  }}
                >
                  <h3 className="dashboardCategoryList-item-title-alternate">
                    {title}
                  </h3>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>

      <div>
        <h2 className="dashboardCategoryList-header-title">Overig</h2>

        <ul className="dashboardCategoryList-content">
          {bottomList.map(
            ({ Icon, title, description, link, onClick, skipCss }, idx) => (
              <li
                key={idx}
                className="dashboardCategoryList-item"
                onClick={() => {
                  router.push(link);

                  if (onClick) onClick();
                }}
              >
                <Icon
                  {...(!skipCss && {
                    className: 'dashboardCategoryList-item-icon'
                  })}
                />

                <h3 className="dashboardCategoryList-item-title">{title}</h3>

                <p className="dashboardCategoryList-item-description">
                  {description}
                </p>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );

  function handleClick(id, { introduction, category }) {
    if (data.me[id]) router.push(`/${category}`);
    else {
      addUserEvent({
        variables: { type: `dashboard-${id}-category-click`, data: '{}' }
      });

      router.push(`/${introduction}`);
    }
  }
}
